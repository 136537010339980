<template>
  <div class="my">

    <!-- <van-nav-bar title="我的" /> -->
    <div class="wallet_box">
      <div>
        <van-row>
          <van-col span="4" class="das"><img src="../../assets/images/usdt.png" style="width: 40px;height: 40px;" alt=""></van-col>
          <van-col span="5" class="das"><span style="font-size: 20px;color: #3E74EE;">USDT</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;">{{ userInfo.money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink1">
            <p style="font-size: 16px;color: #3E74EE;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
      <div class="das" style="margin-top: 20px;">
        <p class="btns dis" @click="goRecharge(1)">
          充幣
        </p>
        <p class="btns dis" @click="Withdrawal(1)">
          提幣
        </p>
      </div>
    </div>
    <div class="wallet_box" style="margin-top: 10px;">
      <div>
        <van-row>
          <van-col span="4" class="das"><img src="../../assets/images/xg/LOGO.svg" style="width: 40px;height: 40px;" alt=""></van-col>
          <van-col span="5" class="das"><span style="font-size: 20px;color: #3E74EE;">Roc</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;">{{ userInfo.personal_money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink2">
            <p style="font-size: 16px;color: #3E74EE;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
      <div class="das" style="margin-top: 20px;">
        <p class="btns dis" @click="goRecharge(2)">
          充幣
        </p>
        <p class="btns dis" @click="Withdrawal(2)">
          提幣
        </p>
      </div>
    </div>
    <div style="margin-left: 40%;font-size: 20px;margin-top: 10%;color: aliceblue;">鎖倉錢包</div>
    <div class="wallet_box" style="margin-top: 10px;">
      <div>
        <van-row>
          <van-col span="4" class="das"><img src="../../assets/images/xg/LOGO.svg" style="width: 40px;height: 40px;" alt=""></van-col>
          <van-col span="5" class="das"><span style="font-size: 20px;color: #3E74EE;">Roc</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;">{{ userInfo.shouyi_money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink3">
            <p style="font-size: 16px;color: #3E74EE;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
     <!-- <div class="das" style="margin-top: 20px;">
        <p class="btns dis" @click="goRecharge(2)">
          充幣
        </p>
        <p class="btns dis" @click="Withdrawal(2)">
          提幣
        </p>
      </div> -->
    </div>
   <!-- <div class="wallet_box" style="margin-top: 10px;">
      <div>
        <van-row>
          <van-col span="10" class="das"><span style="font-size: 20px;color: #3E74EE;">推廣總收益</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;">{{ userInfo.shouyi_money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink3">
            <p style="font-size: 16px;color: #3E74EE;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
    </div> -->
    <div class="banner df fdc jcsb bsbb">

      <!-- <div class="bord bsbb df fdc" style="color: #fff">
        <div class="df aic jcsb">
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">USDT</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.money }}</p>
          </div>
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">ROC</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.personal_money }}</p>
          </div>
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">收益钱包</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.shouyi_money }}</p>
          </div>
        </div>
        <div class="df aic jcsb mt40">
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">总消费券</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.consume_ticket }}</p>
          </div>
          <div class="df aic fdc">
            <p class="fz24 fw7" @click="routerlink">总分红权</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.fenhong_power }}</p>
          </div>
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">累计收益</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.green_score_reward }}</p>
          </div>
        </div>
        <div style="font-size: 14px" class="mb30 fw7" @click="routerlink">{{ userInfo.money }}</div>
        <div style="font-size: 14px">
          分享额度 : {{ userInfo.consume_score }}
        </div>
       <div class="btns mt40 fw7 df">
          <div class="charge df aic jcc mr30" @click="goRecharge">
            <van-button class="charge" @click="goRecharge">充值</van-button>
          </div>
          <div class="upto df aic jcc" @click="goUpto">
            <van-button class="upto" @click="goUpto">提现</van-button>
          </div>
        </div>
      </div> -->

    </div>


    <footer></footer>
  </div>
</template>

<script>
import {
  getUserInfo, AssetWithdrawal
} from "@/utils/api.js";
import { Toast } from "vant";

export default {
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {

    routerlink1() {
      this.$router.push("/user/detailed");
    },
    routerlink2() {
      this.$router.push("/user/detailedroc");
    },
    routerlink3() {
      this.$router.push("/user/detailedshouyi");
    },
    goRecharge(rechargeAmount) {
      this.$router.push({
        path: "/user/bscRecharge",
        query: {
          amount: rechargeAmount
        }
      });
    },

    Withdrawal(rechargeAmount) {
      this.$router.push({
        path: "/user/withdraw",
        query: {
          amount: rechargeAmount
        }
      });
    },

    // Withdrawal(id) {
    //   console.log(1);
    //   const moneys = id === 1 ? this.userInfo.money : this.userInfo.personal_money
    //   if (moneys < 100.00) {
    //     Toast({
    //       message: '余额不足100',
    //       icon: 'fail',
    //       className: 'noticeWidth'
    //     });
    //     return
    //   }
    //   AssetWithdrawal({
    //       type: id,
    //       money: moneys,
    //     }).then((res) => {
    //     console.log(res);
    //   })
    // }
  },
  mounted() {
    getUserInfo().then((res) => {
      this.userInfo = res.data.user;

    });
    // getBannerClassReq({ id: 1 }).then((res) => {
    //   console.log(res);
    // });
  },
};
</script>

<style lang="scss" scoped>
.my{
  background-color: #181a20 ;
  height: 100%;
  width: 100%;
}
.wallet_box {
  color: #FFFFFF;
  font-weight: bold;
  border: #FFFFFF;
  background: rgb(30, 35, 41);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  padding: 40px 20px;
}

.btns {
  margin-right: 20px;
}

.btns:nth-child(1) {
  width: 190px;
  height: 62px;
  background: #3E74EE;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 28px;
  color: white;
}

.btns:nth-child(2) {
  width: 190px;
  height: 62px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #3E74EE;
  color: #3E74EE;
  font-size: 28px;
}



footer {
  height: 100px;
}

.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}
</style>

